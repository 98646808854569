import { Root, rootLoader as loader, useLoaderData, useLocation, useNavigation } from '@ubo/losse-sjedel';
import type { LinksFunction, RootProps } from '@ubo/losse-sjedel';
import PageNotFound from '~/components/layout/PageNotFound';
import styles from '~/global.css';
import stylesProgress from 'nprogress/nprogress.css';
import FlexContent from './graphql/FlexContent';
import Queries from './graphql/Queries';
import PostTypes from './graphql/PostTypes';
import Progress from 'nprogress';
import { useEffect } from 'react';

export const flexData = {
  flexContent: FlexContent,
  queries: Queries,
  postTypes: PostTypes
};

export const links: LinksFunction = () => {
  return [
  {
    rel: 'preload',
    as: 'style',
    href: styles
  },
  {
    href: styles,
    rel: 'stylesheet'
  },
  {
    rel: 'stylesheet',
    href: stylesProgress
  },
  {
    href: '/manifest.json',
    rel: 'manifest'
  },
  {
    rel: 'icon',
    href: '/favicon.png',
    type: 'image/png'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;700&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://use.typekit.net/bny3xpj.css'
  }];

};

export { loader };

export function CatchBoundary() {
  return <PageNotFound />;
}

Progress.configure({ showSpinner: false, easing: 'ease', speed: 500 });

export default function App() {
  const data = useLoaderData<RootProps>();
  const location = useLocation();
  const transition = useNavigation();

  useEffect(() => {
    if (transition.state === 'loading' || transition.state === 'submitting') {
      Progress.start();
    } else {
      Progress.done();
    }
  }, [transition.state]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  return <Root {...data} />;
}